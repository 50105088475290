export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 80 },
    { label: "所属门店", prop: "store_id", slots: "store_id" },
    // { label: "商品ID", prop: "id" },
    // { label: "商品编码", prop: "productCode" },
    { label: "名称", prop: "productName", width: 130 },
    { label: "使用积分", prop: "price", width: 130 },
    { label: "状态", prop: "status", slots: "status" },
    { label: "兑换类型", prop: "exchangeType", slots: "exchangeType" },
    // { label: "商品规格", prop: "isSku", slots: "isSku" },
    { label: "金额", prop: "couponAmount", width: 130 },
    {
      label: "创建时间",
      prop: "createTime",
      width: 180,
      // slots: "state",
    },
    {
      label: "操作",
      prop: "operation",
      width: 180,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
